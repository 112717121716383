import React from "react"
import { LoginComponent } from "@/components/pages"
import LoginLayout from '../layouts/Login'
const IndexPage = (props) => {
	// here we just check if user is already logged in and redirect to profile
	return <LoginComponent />
}
IndexPage.Layout = LoginLayout
IndexPage.displayName = 'Login page'
export default IndexPage
